import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/seo"
import Layout from "../components/layout"

class DieselGeneratorTemplate extends React.Component {
  render() {
    const dG = get(this.props, "data.contentfulDieselGenerator")

    const titleTag = "دیزل ژنراتور " + dG.brand.title + " مدل " + dG.model

    const descriptionTag =
      "دیزل ژنراتور مارک " +
      dG.brand.title +
      " مدل " +
      dG.model +
      " عرضه شده توسط شرکت مشارکت نیرو آریا کیش"
    return (
      <Layout location={this.props.location}>
        <Seo title={titleTag} description={descriptionTag} image={""} />
        <div className="container">
          <h1 className="text-4xl font-semibold px-10 pt-10">{titleTag}</h1>
          <h2 className="text-2xl fornt-semibold px-10 py-5">
            همراه با گارانتی و خدمات پس از فروش شرکت مشارکت نیرو
          </h2>

          <div className="grid grid-cols-2 sm:grid-cols-1 p-10 sm:px-5 sm:py-10  gap-10  bg-primary text-white">
            <div id="image-button" className="">
              <img src={dG.cardImage.file.url} alt="{dg.model}" />
              <button className="bg-secondary text-white text-xl font-semibold px-10 py-2 mt-10 mb-5 rounded max-w-fit shadow-lg hover:bg-slate-200 hover:text-black hover:shadow-secondary">
                <a
                  href={dG.catalog.file.url}
                  target="_blank"
                  rel="noreferrer"
                  id="catalog"
                >
                  ملاحظه فایل PDF
                </a>
              </button>
            </div>

            <section id="generator-details">
              <div className=" overflow-hidden py-2 color-white">
                <table className="text-2xl border table-auto w-full font-normal ">
                  <thead>
                    <tr>
                      <th className="bg-slate-200 text-primary text-right px-5 py-5">
                        مشخصات
                      </th>
                      <th className="bg-slate-200 text-primary  text-right px-5 py-5">
                        مقدار
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        توان استندبای KVA
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.standbyPowerKva}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        توان استندبای KW
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.standbyPowerKwe}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        توان پرایم KVA
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.primePowerKva}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        توان پرايم KW
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.primePowerKwe}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        مدل موتور
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.motorModel}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        مدل ژنراتور
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.generatorModel}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        حجم روغن - لیتر
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.oilCapacity}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        کنترل پنل
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.controlPanel}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        حجم خنک کننده - لیتر
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.coolantCapacity}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        مصرف سوخت @ %75 - لیتر در ساعت
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.fuelUsage75Percent}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-xl text-right px-5 py-2">
                        مصرف سوخت @ %100 - لیتر در ساعت
                      </td>
                      <td className="border border-xl text-right px-5 py-2">
                        {dG.fuelUsage100Percent}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default DieselGeneratorTemplate

export const pageQuery = graphql`
  query DieselGeneratorById($id: String!) {
    contentfulDieselGenerator(id: { eq: $id }) {
      model
      brand {
        title
      }
      standbyPowerKva
      standbyPowerKwe
      primePowerKva
      primePowerKwe
      motorModel
      generatorModel
      oilCapacity
      controlPanel
      coolantCapacity
      fuelUsage75Percent
      fuelUsage100Percent
      catalog {
        title
        file {
          url
        }
      }
      cardImage {
        title
        file {
          url
        }
      }
    }
  }
`
