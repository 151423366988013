import * as React from "react"
import PropTypes from "prop-types"
import { useState } from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { FiX } from "@react-icons/all-files/fi/FiX"
import { FiMenu } from "@react-icons/all-files/fi/FiMenu"

import Logo from "../images/logo/mosharekatniroo-logo.png"

const menuItems = [
  {
    title: "محصولات",
    path: "/diesel-generators",
    icon: "",
  },
  {
    title: "خدمات",
    path: "/services",
    icon: "",
  },
  {
    title: "پروژه ها",
    path: "/projects",
    icon: "",
  },
  {
    title: "وبلاگ",
    path: "/",
    icon: "",
  },
  {
    title: "در باره ما",
    path: "/about-us",
    icon: "",
  },
  {
    title: "تماس با ما",
    path: "/contact-us",
    icon: "",
  },
]

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default function Header({ siteTitle }) {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <>
      <header className=" py-0 ">
        <nav className="nav h-[120px] flex items-center relative px-2 py-0 top-0 w-full bg-gradient-to-b from-secondary to-transparent">
          <Link to="/">
            <img
              src={Logo}
              alt="لوگوی شرکت مشارکت نیرو"
              className=" top-2 right-1"
            />
          </Link>

          <FiMenu
            className={`hamburger hidden justify-left absolute top-30 left-4  w-[40px] h-[40px] rounded-full bg-white text-primary cursor-pointer justify-center px-1 py-1 hover:bg-secondary md:block 
            ${isNavExpanded ? "md:hidden" : "md:block"}`}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          />

          <div className="nav-menu left-auto ">
            <ul
              className={`nav-ul flex p-0  md:absolute md:top-20 md:py-10 md:left-0 md:flex-col md:w-full md:h-screen md:bg-secondary md:z-10
              ${isNavExpanded ? "md:block" : "md:hidden"}`}
            >
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className="mx-0 my-4 md:text-center md:m-5 hover:bg-secondary  md:hover:bg-black"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded)
                  }}
                >
                  <Link
                    className="nav-link block px-4 py-3 w-full text-orange-800 font-semibold hover:bg-secondary  hover:text-white transition-all duration-300 md:w-full md:px-6 md:py-0"
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}
