import React from "react"
import { Link } from "gatsby"

export default function footer() {
  return (
    <footer>
      © {new Date().getFullYear()} &middot; کلیه حقوق این سامانه متعلق به شرکت
      مشارکت نیرو آریا کیش می باشد.
    </footer>
  )
}
